export default defineNuxtRouteMiddleware(async (to, from) => {
  const user = useSanctumUser();
  const toast = useToast();

  if (!user.value?.email_verified_at) {
    if (to.path !== '/profile') {
      return navigateTo('/profile');
    }

    toast.add({
      title: 'Sua conta ainda não foi confirmada!',
      description: 'Verifique seu e-mail para confirmar sua conta.',
      ui: {
        background: 'bg-orange-accent',
        title: 'text-white',
        description: 'text-white',
        icon: { color: 'text-white' },
      },
      timeout: 6000,
    });
  }
});
